import { Box, Stack } from "@mantine/core";
import { ITractableTheme } from "@tractable/frame-ui";
import { UserPool } from "@tractableai/auth-management-client";
import classNames from "classnames";
import { createUseStyles } from "react-jss";
import { RuntimeConfigContext } from "../context/ConfigProvider";
import {
  makeUserPoolLocalStorageKey,
  UserPoolContext,
} from "../context/UserPoolProvider";

const ClientList = () => {
  const { userPool, setUserPool, userPools } = UserPoolContext.useValue();
  const config = RuntimeConfigContext.useValue();

  const classes = useStyles();

  const handleClick = (selectedUserPool: UserPool) => {
    setUserPool(selectedUserPool);
    localStorage.setItem(
      makeUserPoolLocalStorageKey(config),
      selectedUserPool.userPoolId
    );
  };

  return (
    <Stack spacing={0} className={classes.clientStack}>
      <Box className={classes.baseItem}>
        <b>Clients</b>
      </Box>
      {userPools.map((pool) => {
        return (
          <Box
            key={pool.userPoolId}
            className={classNames(classes.baseItem, classes.button, {
              [classes.active]: userPool.userPoolId === pool.userPoolId,
            })}
            onClick={() => handleClick(pool)}
          >
            {pool.clientId}
          </Box>
        );
      })}
    </Stack>
  );
};

const useStyles = createUseStyles((theme: ITractableTheme) => ({
  clientStack: {
    overflow: "auto",
  },
  baseItem: {
    paddingLeft: "1em",
    paddingRight: "1em",
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
  },
  button: {
    cursor: "pointer",
    "&:hover": {
      background: theme.colour.Blue10,
    },
  },
  active: {
    background: theme.colour.Grey5,
  },
}));

export default ClientList;
