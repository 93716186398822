/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Auth Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUserPoolRequest
 */
export interface CreateUserPoolRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserPoolRequest
     */
    clientId: string;
    /**
     * Language code for the user pool. Defaults to "en".
     * @type {string}
     * @memberof CreateUserPoolRequest
     */
    language?: string;
    /**
     * Tractable environment
     * @type {string}
     * @memberof CreateUserPoolRequest
     */
    env?: string;
    /**
     * If true, will use an existing user pool. If false, the API will throw on an existing user pool. Defaults to false.
     * @type {boolean}
     * @memberof CreateUserPoolRequest
     */
    useExistingPool?: boolean;
    /**
     * Optional callback URLs for your product are allowed to receive access tokens in the auth flow.
     * @type {Array<string>}
     * @memberof CreateUserPoolRequest
     */
    callbackUrls?: Array<string>;
}


/**
 * Check if a given object implements the CreateUserPoolRequest interface.
 */
export function instanceOfCreateUserPoolRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clientId" in value;

    return isInstance;
}

export function CreateUserPoolRequestFromJSON(json: any): CreateUserPoolRequest {
    return CreateUserPoolRequestFromJSONTyped(json, false);
}

export function CreateUserPoolRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserPoolRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['clientId'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'env': !exists(json, 'env') ? undefined : json['env'],
        'useExistingPool': !exists(json, 'useExistingPool') ? undefined : json['useExistingPool'],
        'callbackUrls': !exists(json, 'callbackUrls') ? undefined : json['callbackUrls'],
    };
}

export function CreateUserPoolRequestToJSON(value?: CreateUserPoolRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientId': value.clientId,
        'language': value.language,
        'env': value.env,
        'useExistingPool': value.useExistingPool,
        'callbackUrls': value.callbackUrls,
    };
}

